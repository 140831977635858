import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout.js"

function mapDataToLinks(data)
{
    return data.allDirectory.nodes.map((node,index) => {
        const { name } = node;
        return (
            <li key={index}>
                <Link to={name}> {name} </Link>
            </li>
        );
    });
}

export default function Katas({ data })
{
    const links = mapDataToLinks(data);
    return (
        <Layout>
            <h1> This is an overview of all the repos </h1>
            <ul>
                { links }
            </ul>
        </Layout>
    );
}

export const query = graphql`
query {
  allDirectory(filter: {relativeDirectory: {eq: "repos"}}) {
    nodes {
      name
      relativeDirectory
      relativePath
    }
  }
}
`
